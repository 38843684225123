<template>
  <div class="contact">
    <coming-soon />
  </div>
</template>

<script>
import ComingSoon from "../components/comingSoon";
export default {
  name: "contact",
  components: {ComingSoon}
}
</script>

<style scoped>
.contact {
  color: #11142C;
}
</style>